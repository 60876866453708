import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import {useQuery} from "@apollo/react-hooks";
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import 'react-phone-input-2/lib/style.css';
import Colors from '../../../theme/Colors';
import TextFieldSearch from "../../../components/TextField/TextFieldSearch";
import { TextField } from "../../../components/TextField";
import { useMutation, useApolloClient, useLazyQuery } from 'react-apollo';
import {
  DROPDOWN_MUNICIPIOS,
  CONSULTA_CEP,
  FIND_ALL_ESPANHA_COMUNIDADE_AUTONOMA,
  FIND_PROVINCIA,
  FIND_ESPANHA_MUNICIPIOS
} from '../../../graphql/queryes';
import { UPDATE_UNIDADE, UPDATE_REDE } from '../../../graphql/mutations';
import Notification from '../../../components/notification/Notification';
import string from '../../../utils/string';
import { SelectSearch } from '../../../components/select/SelectSearch';
import InputPhoneDdi from '../../../components/input/InputPhoneDdi';
import RenderTextField from '../../../components/TextField/RenderTextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox } from '@material-ui/core';

const notificationDefault = {
  variant: '',
  message: '',
  isOpen: false,
};

const EditarUnidade = ({ venda, onClose, onSave }) => {
  const [notification, setNotification] = useState({
    ...notificationDefault,
  });
  const [formData, setFormData] = useState({
    codigoCnes: venda.unidade?.codigoCnes,
    nome: venda.unidade?.nome,
    nomeFantasia: venda.unidade?.nomeFantasia,
    telefonePrincipal: venda.unidade?.telefonePrincipalDDI ? `${venda.unidade?.telefonePrincipalDDI} ${venda.unidade?.telefonePrincipal}` : venda.unidade?.telefonePrincipal,
    telefonePrincipalDDI: venda.unidade?.telefonePrincipalDDI,
    cep: venda.unidade?.endereco?.cep,
    bairro: venda.unidade?.endereco?.bairro,
    municipio: venda.unidade?.endereco?.municipio,
    numero: venda.unidade?.endereco?.numero,
    nomeLogradouro: venda.unidade?.endereco?.nomeLogradouro,
    complemento: venda.unidade?.endereco?.complemento,
    paisAtuacao: venda.unidade?.rede?.paisAtuacao,
    espanhaMunicipio: venda.unidade?.espanhaMunicipio,
    provincia: venda.unidade?.espanhaMunicipio?.provincia,
    comunidadeAutonoma: venda.unidade?.espanhaMunicipio?.provincia?.comunidadeAutonoma,
    perfilPublico: venda.unidade?.perfilPublico,
  });
  const [salvando, setSalvando] = useState(false);

  const client = useApolloClient();
  const [updateUnidade] = useMutation(UPDATE_UNIDADE);

  const {data: comunidadesAutonoma} = useQuery(FIND_ALL_ESPANHA_COMUNIDADE_AUTONOMA);
  const [getConsultaCep, {data: dadosConsultaCep}] = useLazyQuery(CONSULTA_CEP);
  const [getProvincias, {data: provincias}] = useLazyQuery(FIND_PROVINCIA);

  const whitelabel = venda?.unidade?.rede?.whitelabel?.nomeEmpresa

  const handleSubmit = async e => {
    e.preventDefault();
    if(isDadosUnidadeInvalidos()){
      setNotification({
        variant: 'error',
        isOpen: true,
        message: 'Verifique os dados novamente',
      });

      return null;
    }

    try {
      setSalvando(true);

      await updateUnidade({
        variables: {
          unidade: {
            id: venda.unidade?.id,
            codigoCnes: formData.codigoCnes,
            nome: formData.nome,
            nomeFantasia: formData.nomeFantasia,
            telefonePrincipal: string.removeSpecialChars(formData.telefonePrincipal).substr(formData.telefonePrincipalDDI.length),
            telefonePrincipalDDI: formData.telefonePrincipalDDI,
            endereco: {
              cep: string.removeSpecialChars(formData.cep),
              bairro: formData.bairro,
              municipio: formData.paisAtuacao?.descricao === "BRASIL" ? {
                id: formData.municipio?.value,
              } : null,
              numero: formData.numero,
              nomeLogradouro: formData.nomeLogradouro,
              complemento: formData.complemento,
            },
            espanhaMunicipio: formData.paisAtuacao?.descricao === "ESPANHA" ? {id: formData.espanhaMunicipio.id} : null,
            perfilPublico: formData?.perfilPublico || false,
          }
        },
      });

      onSave && onSave();
      handleClose();
    } catch ({ graphQLErrors }) {
      setSalvando(false);
      if (graphQLErrors) {
        setNotification({
          variant: 'error',
          isOpen: true,
          message: graphQLErrors[0].message,
        });
      }
    }
  };

  const consultaCep = async (cep) => {
    await getConsultaCep({
      variables: {
        cep: cep
      }
    });
  };

  const isCEPInvalido = () => {
    if (formData.paisAtuacao?.descricao === 'BRASIL') {
      return (
        formData.cep === undefined ||
        string.removeSpecialChars(formData.cep)?.length < 8
      );
    } else {
      return formData.cep === undefined || formData.cep === '';
    }
  };

  const isMunicipioInvalido = () => {
    if (formData.paisAtuacao?.descricao === 'BRASIL') {
      return !formData.municipio;
    } else {
      return !formData.espanhaMunicipio;
    }
  };

  const isDadosUnidadeInvalidos = () => {
    const telefoneSemDDI = formData.telefonePrincipalDDI ? formData.telefonePrincipal.substring(2) : formData.telefonePrincipal

    return (
      !formData.nome ||
      !string.validaTelefoneDDI(
        telefoneSemDDI,
        formData.telefonePrincipalDDI
      ) ||
      isCEPInvalido() ||
      !formData.bairro ||
      !formData.nomeLogradouro ||
      isMunicipioInvalido() ||
      !formData.paisAtuacao
    );
  };

  const handleClose = () => {
    onClose && onClose();
  };

  const handleLoadMoreOptions = async ({
    variables,
    query,
    responseObject,
  }) => {
    const response = await client.query({
      query,
      variables,
    });

    const pageNumber = variables?.searchDTO?.pageNumber || 0;

    let options = response?.data?.[responseObject]?.content || [];

    if (responseObject === 'findAllMunicipio') {
      options = options.map((o) => ({
        ...o,
        label: `${o.label} - ${o.uf}`,
      }));
    }

    return {
      options,
      hasMore: !response?.data?.[responseObject]?.last,
      additional: {
        page: pageNumber + 1,
      },
    };
  };

  const handleMunicipiosLoadOptions = async (
    search,
    loadedOptions,
    { page }
  ) => {
    return handleLoadMoreOptions({
      loadedOptions,
      variables: {
        searchDTO: {
          pageSize: 10,
          pageNumber: page,
          search,
        },
      },
      responseObject: 'findAllMunicipio',
      query: DROPDOWN_MUNICIPIOS,
    });
  };

  const onTelefonePrincipalComDDIChange = (value, data) => {
    setFormData({
      ...formData,
      telefonePrincipal:
        data.dialCode === formData.telefonePrincipalDDI ? value : data.dialCode,
      telefonePrincipalDDI: data.dialCode,
    });
  };

  const renderTextField = (label, field, required) => {
    const error = required && !formData[field];
    return (
      <>
        <Typography color="black" component="label">
          {required ? label + '*' : label}
        </Typography>
        <TextField
          value={formData[field]}
          onChange={(e) =>
            setFormData({
              ...formData,
              [field]: e.target.value,
            })
          }
          error={error}
          helperText={error && 'Campo obrigatório'}
        />
      </>
    );
  };

  const renderPhoneField = () => {
    return (
      <InputPhoneDdi
        label="Telefone Principal *"
        onTelefonePrincipalComDDIChange={onTelefonePrincipalComDDIChange}
        telefone={formData.telefonePrincipal}
      />
    );
  };

  const renderCEPField = (label, field, required) => {
    const mask =
      formData.paisAtuacao?.descricao === 'ESPANHA' ? 'codigoPostal' : 'cep';
    let error = false;

    if (mask === 'cep') {
      error =
        required &&
        string.removeSpecialChars(formData?.cep)?.length < 8 &&
        !dadosConsultaCep;
    }

    const handleChangeCep = (e) => {
      const cpfSemMascara = string.removeSpecialChars(e.target.value);
      setFormData({
        ...formData,
        [field]: e.target.value,
      });

      if (mask === 'cep' && cpfSemMascara.length === 8) {
        consultaCep(cpfSemMascara);
      }
    };

    return (
      <>
        <Typography color="black" component="label">
          {required ? label + '*' : label}
        </Typography>
        <TextField
          value={formData[field]}
          mask={mask}
          onChange={(e) => handleChangeCep(e)}
          error={error}
          helperText={error && 'CEP inválido'}
        />
      </>
    );
  };

  const renderTextFieldSearchPais = (label) => (
    <RenderTextField
      label={label}
      field={formData.paisAtuacao?.label}
      disabled={true}
    />
  );

  const renderTextFieldSearch = (label, field, loadOptions) => (
    <>
      <Typography color="black" component="label">
        {label}
      </Typography>
      <TextFieldSearch
        placeholder=""
        loadOptions={loadOptions}
        withPaginate
        value={formData[field]}
        onChange={e =>
          setFormData({
            ...formData,
            [field]: e,
          })
        }
        debounceTimeout={300}
        additional={{
          page: 0
        }}
      />
    </>
  );

  const renderSelectSearch = (label, field, list, onChangeSelect, disabled = false) => {
    return(
      <>
        <Typography color="black" component="label">
          {label}
        </Typography>
        <SelectSearchStyled
          placeholder=""
          value={formData[field]}
          elements={list}
          onChange={onChangeSelect}
          isDisabled={disabled}
        />
      </>
    )
  };

  const renderTextFieldSearchEspanhaMunicipio = (label, field, loadOptions, disabled) => (
    <>
      <Typography color="black" component="label">
        {label}
      </Typography>
      <TextFieldSearch
        key={formData.provincia?.value}
        placeholder=""
        loadOptions={loadOptions}
        withPaginate
        value={formData[field]}
        onChange={e =>
          setFormData({
            ...formData,
            [field]: e,
          })
        }
        debounceTimeout={300}
        additional={{
          page: 0
        }}
        isDisabled={disabled}
        menuInTop
      />
    </>
  );

  const onEspanhaComunidadeAutonomaChange = comunidadeAutonoma => {
    setFormData({
      ...formData,
      comunidadeAutonoma,
      provincia: null,
      espanhaMunicipio: null,
    });
    findProvinciaBy(comunidadeAutonoma);
  };

  const findProvinciaBy = (codigoComunidadeAutonoma) => {
    getProvincias({
      variables: {
        pageableDTO: {sortField: "descricao", sortDir: "ASC"},
        codigoComunidadeAutonoma: codigoComunidadeAutonoma?.value
      }
    });
  };

  const onEspanhaProvinciaChange = provincia => {
    setFormData({
      ...formData,
      provincia,
      espanhaMunicipio: null,
    });
  };

  const handleChangeField = (campo, value) => {
    setFormData({
      ...formData,
      [campo]: value
    });
  };


  const handleEspanhaMunicipioLoadOptions = async (search, loadedOptions, { page }) => {
    return handleLoadMoreOptions({
      loadedOptions,
      variables: {
        pageableDTO: {
          pageSize: 10,
          pageNumber: page,
          sortDir: "ASC",
          sortField: "descricao"
        },
        codigoProvincia: formData.provincia?.value,
        descricao: search
      },
      responseObject: 'findAllEspanhaMunicipio',
      query: FIND_ESPANHA_MUNICIPIOS
    });
  };

  useEffect(() => {
    if (formData.paisAtuacao?.label === "BRASIL" && dadosConsultaCep?.consultaDeCep) {
      setFormData({
        ...formData,
        nomeLogradouro: dadosConsultaCep?.consultaDeCep?.logradouro,
        bairro: dadosConsultaCep?.consultaDeCep?.bairro,
        municipio: {...dadosConsultaCep.consultaDeCep?.municipio, label: `${dadosConsultaCep.consultaDeCep?.municipio?.label || '-'} - ${dadosConsultaCep.consultaDeCep?.municipio?.uf || '-'}`},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dadosConsultaCep]);

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <GridStyled container direction="row" spacing={1}>
          <FormGroup item xs={12}>
            {renderTextFieldSearchPais('País de atuação*')}
          </FormGroup>
          <FormGroup item xs={6}>
            {renderTextField('Nome', 'nome', true)}
          </FormGroup>
          <FormGroup item xs={6}>
            {renderTextField('Nome Fantasia', 'nomeFantasia', false)}
          </FormGroup>
          <FormGroup item xs={6}>
            {renderTextField('Código CNES', 'codigoCnes', false)}
          </FormGroup>
          <FormGroup item xs={6}>
            {renderPhoneField()}
          </FormGroup>
          <FormGroup item xs={6}>
            {renderCEPField(formData.paisAtuacao?.descricao === "BRASIL" ? 'CEP' : "Código postal", 'cep', true)}
          </FormGroup>
          <FormGroup item xs={6}>
            {renderTextField('Logradouro', 'nomeLogradouro', true)}
          </FormGroup>
          <FormGroup item xs={6}>
            {renderTextField('Número', 'numero', true)}
          </FormGroup>
          <FormGroup item xs={6}>
            {renderTextField('Complemento', 'complemento', false)}
          </FormGroup>
          <FormGroup item xs={6}>
            {renderTextField('Bairro', 'bairro', true)}
          </FormGroup>
          {formData.paisAtuacao?.descricao === "BRASIL" ?
            <FormGroup item xs={6}>
              {renderTextFieldSearch('Município*', 'municipio', handleMunicipiosLoadOptions)}
            </FormGroup>
            :
            <>
              <FormGroup item xs={6}>
                {renderSelectSearch('Comu. autonoma*', 'comunidadeAutonoma', comunidadesAutonoma?.findAllEspanhaComunidadeAutonoma || [], onEspanhaComunidadeAutonomaChange)}
              </FormGroup>
              <FormGroup item xs={6}>
                {renderSelectSearch('Província*', 'provincia', provincias?.findAllEspanhaProvincia || [], onEspanhaProvinciaChange, !formData.comunidadeAutonoma)}
              </FormGroup>
              <FormGroup item xs={6}>
                {renderTextFieldSearchEspanhaMunicipio('Município*', 'espanhaMunicipio', handleEspanhaMunicipioLoadOptions, !formData.provincia)}
              </FormGroup>
            </>
          }
          <FormGroup item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                  checked={formData.perfilPublico}
                    onChange={() => handleChangeField('perfilPublico', !formData.perfilPublico)}
                    color="black"
                  />
                }
                label={`Ativar ${whitelabel}`}
                labelPlacement="start"
                style={{ marginRight: 20 }}
              />
          </FormGroup>
        </GridStyled>
        <ButtonsContainer item xs={12}>
          <Button type="submit" disabled={salvando || isDadosUnidadeInvalidos()}>{salvando ? 'Salvando...' : 'Salvar'}</Button>
        </ButtonsContainer>
      </Form>
      <Notification
        close={() => {
          setNotification({
            ...notificationDefault,
          });
        }}
        reset={() => {
          setNotification({
            ...notificationDefault,
          });
        }}
        isOpen={notification.isOpen}
        variant={notification.variant}
        message={notification.message}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Form = styled.form`
  width: 96%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const FormGroup = styled(Grid)`
	margin-bottom: 4px !important;
`;

const ButtonsContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 115px;
  align-items: end;
`;

const Button = styled.button`
  border: 0;
  background-color: ${Colors.primary.main};
  color: #fff;
  padding: 5px 10px;
  box-sizing: border-box;
  height: 34px;
  min-width: 150px;
  border-radius: 6px;
  margin: 8px 0;
  cursor: pointer;
  border-radius: 100px;

  &:disabled {
    background-color: ${Colors.commons.gray};
  }
`;

const SelectSearchStyled = styled(SelectSearch)`
  width: 100%;
`;

const GridStyled = styled(Grid)`
  height: 100%;
  overflow: auto;
`;

export default EditarUnidade;
