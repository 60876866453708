import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { columnsProfissionaisSaude } from '../constants';
import List from '../../../components/list/list';

const ProfissionaisSaude = ({
  pageableDTO,
  profissionais,
  findProfissionaisSaudeByVenda,
  handleEditProfissionalSaude,
  loadingProfissionaisSaudeVenda,
  withMarginTop,
  setPageableDTO
}) => {
  const params = useParams();
  const [profissionaisLoaded, setProfissionaisLoaded] = useState([]);
  const { content, last } = profissionais?.findProfissionaisSaudeByVenda || {};

  const handleLoadMore = () => {
    findProfissionaisSaudeByVenda({
      variables: {
        venda: {id: params.vendaId},
        pageableDTO: {
          ...pageableDTO,
          pageNumber: pageableDTO.pageNumber + 1,
        },
      },
    });
    setPageableDTO({
      ...pageableDTO,
      pageNumber: pageableDTO.pageNumber + 1,
    })
  };

  useEffect(() => {
    findProfissionaisSaudeByVenda({
      variables: {
        venda: {id: params.vendaId},
        pageableDTO,
      },
    });

    return () => {
      setProfissionaisLoaded([]);
      setPageableDTO({
        ...pageableDTO,
        pageNumber: 0
      });
    }
  }, []);

  useEffect(() => {
    const { pageNumber } = pageableDTO;
    const updatedProfissionais = pageNumber === 0 ? content : [...profissionaisLoaded, ...content || []];
    setProfissionaisLoaded(updatedProfissionais);
  }, [profissionais]);

  return (
    <Content withMarginTop={withMarginTop}>
      <Header> Profissionais de saúde </Header>
      <ListProfissionais
        columns={columnsProfissionaisSaude({ vendaId: params.vendaId })}
        items={profissionaisLoaded || []}
        loading={loadingProfissionaisSaudeVenda}
        last={last}
        onClickItem={handleEditProfissionalSaude}
        onLoadMore={handleLoadMore}
      />
    </Content>
  );
};

export default ProfissionaisSaude;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.withMarginTop ? '24px' : '0'};
`;

const Header = styled.label`
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
`;

const ListProfissionais = styled(List)`
  overflow: auto;
  max-height: 800px;
`;
